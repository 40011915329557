import React, { useEffect, useState } from "react";
import { object, shape } from "prop-types";
import { replaceAllStoriesInCollection, WithPreview } from "@quintype/components";
import DesktopComponents from "../../components/desktop-components/DesktopComponents";
import MobileComponents from "../../components/mobile-components/MobileComponents";
import HomePageSkeleton from "../skeleton/HomePageSkeleton";
import { parseUrl } from "query-string";
import { completePasswordLessSignIn } from "../../../server/helpers/auth";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { MagicLink } from "../../utils/gtm";
import SubscribeTopBanner from "../templates/SubscribeTopBanner";

export const HomePage = (props) => {
  const getState = useSelector((state) => state);
  const currentPath = get(getState, ["qt", "currentPath"], "");
  const [isMobile, setIsMobile] = useState(null);
  const params = parseUrl(currentPath);
  const [action, setAction] = useState(null);
  const [code, setCode] = useState(null);
  const getEmail = () => {
    if (typeof window !== "undefined" && window.localStorage) {
      const storedUser = sessionStorage.getItem("SUBSCRIBED_USER");
      return storedUser ? JSON.parse(storedUser) : null;
    }
    return null;
  };

  const getUserProfileId = () => {
    if (typeof window !== "undefined" && window.sessionStorage) {
      const storedProfileId = sessionStorage.getItem("USER_PROFILE_ID");
      return storedProfileId ? JSON.parse(storedProfileId) : null;
    }
    return null;
  };

  const profileId = useSelector((state) => get(state, ["userProfileID"], null)) || getUserProfileId();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setAction(params.query.mode);
      setCode(params.query.oobCode);

      const localTheme = localStorage.getItem("theme") || "light";
      document.documentElement.classList.add(localTheme);
    }
  }, []);
  if (action === "resetPassword") {
    window.location.replace(`/auth/reset-password?oobCode=${code}`);
    return;
  }
  if (action === "signIn") {
    const email = typeof window !== undefined ? localStorage.getItem("userEmail") : null;

    completePasswordLessSignIn(window.location.href, email).then((res) => {
      if (res) {
        MagicLink();
        window.location.replace("/");
        cdpEventHandler({
          email: email,
          event_type: "reader_login",
          event_time: new Date().toISOString(),
          source: "website",
          profile_id: profileId,
        });
      }
    });
  }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await getCollections("politics");
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="mx-auto container">
      <SubscribeTopBanner props={props} />
      {isMobile === null ? (
        <HomePageSkeleton />
      ) : isMobile ? (
        <MobileComponents props={props} />
      ) : (
        <DesktopComponents props={props} />
      )}
    </div>
  );
};

HomePage.propTypes = {
  data: shape({
    collection: object,
  }),
};

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story),
  })
);
